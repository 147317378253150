import datepicker from 'js-datepicker'
import Typed from 'typed.js/src/typed'

import Inputs from './partials/inputs'
import Select from './partials/select'

import cookieNotificationLitener from './partials/cookie-notification'

import SlideToggle from './partials/customSlideToggle'

class Gedr {
    constructor() {
        this.inputs = new Inputs()

        this.customSelects()
        this.datepickers()
        this.tabs()
        this.faq()
        this.mobileNav()
        this.footerMobileTogglers()

        this.typed()

        this.mobilePartiesToggler()

        cookieNotificationLitener()

        new SlideToggle()
    }

    customSelects() {
        const selects = document.querySelectorAll('select[data-select]')

        selects.forEach($el => {
            const select = new Select($el)
        })
    }

    datepickers() {
        const datepickerInputs = document.querySelectorAll('[data-datepicker]')
        datepickerInputs.forEach($el => {
            const picker = datepicker($el, {
                position: 'br',
            })
        })
    }

    tabs() {
        const $tabTitles = document.querySelectorAll('[data-tab-title]')
        const setLinerStyles = _ => {
            $tabTitles.forEach($el => {
                if ($el.classList.contains('active')) {
                    const width = $el.offsetWidth
                    const leftOffset = $el.offsetLeft
                    $el
                        .closest('[data-tab-titles]').style
                        .setProperty('--liner-width', width + 'px')
                    $el
                        .closest('[data-tab-titles]').style
                        .setProperty('--liner-left-offset', leftOffset + 'px')
                }
            })
        }
        setLinerStyles()

        $tabTitles.forEach($el => {
            $el.addEventListener('click', clickHandler)
        })

        function clickHandler(e) {
            const $this = e.currentTarget
            const index = indexInParent($this)

            $this
                .closest('[data-tab-titles]')
                .querySelectorAll('[data-tab-title]')
                .forEach($el => $el.classList.remove('active'))
            $this.classList.add('active')

            $this
                .closest('[data-tabs]')
                .querySelectorAll(`[data-tab-content]:not(:nth-child(${index + 1}))`)
                .forEach($el => $el.classList.remove('active'))

            $this
                .closest('[data-tabs]')
                .querySelector(`[data-tab-content]:nth-child(${index + 1})`)
                .classList.add('active')

            setLinerStyles()
        }
    }

    faq() {
        document.querySelectorAll('.section--faq__q').forEach($el => {
            $el.addEventListener('click', e => {
                e.currentTarget.closest('.section--faq__item').classList.toggle('active')
            })
        })
    }

    typed() {
        const elements = document.querySelectorAll('[data-typed]')

        elements.forEach(el => {
            const strings = el.dataset.typed.split('|')

            const typed = new Typed(el, {
                strings,
                typeSpeed: 150,
                loop: true,
                backSpeed: 100,
                backDelay: 3000,
            });
        })
    }

    mobileNav() {
        const $openBtn = document.querySelector('.mobile-open-nav-btn')
        const $nav = document.querySelector('.header .nav')
        const $closeBtn = $nav ? $nav.querySelector('.close') : null

        $openBtn && $openBtn.addEventListener('click', e => {
            e.preventDefault()
            $nav.classList.add('active')
            document.body.classList.add('modal-opened')
        })
        $closeBtn && $closeBtn.addEventListener('click', e => {
            e.preventDefault()
            $nav.classList.remove('active')
            document.body.classList.remove('modal-opened')
        })
        $nav && $nav.addEventListener('click', e => {
            if (e.target === e.currentTarget) {
                e.preventDefault()
                $nav.classList.remove('active')
                document.body.classList.remove('modal-opened')
            }
        })
    }

    footerMobileTogglers() {
        const $togglers = document.querySelectorAll('.footer-top-column .name')

        $togglers.forEach($el => {
            $el.addEventListener('click', e => {
                e.preventDefault()
                $togglers.forEach($t => {
                    if ($t !== $el)
                        $t.closest('.footer-top-column').classList.remove('show-links')
                })
                $el.closest('.footer-top-column').classList.toggle('show-links')
            })
        })
    }

    mobilePartiesToggler() {
        const $togglers = document.querySelectorAll('.case-page__parties-title')

        $togglers.forEach($el => {
            $el.addEventListener('click', e => {
                e.preventDefault()
                $togglers.forEach($t => $t.classList.remove('active'))
                e.currentTarget.classList.add('active')
            })
        })
    }
}

document.addEventListener('DOMContentLoaded', _ => {
    new Gedr()
})

// function checkScrollDirectionIsUp(event) {
//     if (event.wheelDelta) {
//         return event.wheelDelta > 0;
//     }
//     return event.deltaY < 0;
// }

function indexInParent(node) {
    let children = node.parentNode.childNodes
    let num = 0
    for (let i = 0; i < children.length; i++) {
        if (children[i] === node) return num
        if (children[i].nodeType === 1) num++
    }
    return -1
}
